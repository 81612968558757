@import "node_modules/bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:300,500,700&display=swap&subset=japanese');


/* ===============================
	base
 =============================== */

body {
	font-family: "Noto Sans JP", -apple-system, BlinkMacSystemFont, "Futura", "Helvetica Neue", "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif;
	line-height: 1.8;
	color: #22222c;
	 font-size: $font-size-sm;
	font-weight: 400;
  -webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @media (min-width: 768px) {
	  font-size: 1rem;
  }
  &#home {
		display: none;
  }
}

* { position: relative; box-sizing: border-box; }

a { 
	transition: .3s;
	&:focus { outline: none; }
	&:active { text-decoration: none; }
}



/* ===============================
	utility
 =============================== */

.sp-only { 
	display: inline-block; 
	@media (min-width: 768px) {
		display: none;
	}
}
.pc-only { 
	display: none; 
	@media (min-width: 768px) {
		display: inline-block;
	}
}

img {
	display: block;
	width: 100%;
}



/* ===============================
	color
 =============================== */

.color-orange {
	color: #ffc000;
}

.bg-gray {
	background-color: #eeeeee;
}


/* ===============================
	typography
 =============================== */
 
.font-light { font-weight: 300; }
.font-bold { font-weight: 700; }

.site-logo {
	display: block;
	width: 120px;
	height: 30px;
	@media (min-width: 768px) {
		width: 158px;
		height: 40px;
	}
}

h2 {
	margin-bottom: 2.5rem;
	font-size: 27px;
	
	@media (min-width: 768px) {
		font-size: 48px;
		font-weight: 300;
	}
	@media (min-width: 992px) {
		font-size: 60px;
	}
}

h3 {
	margin-bottom: 30px;
	font-size: 30px;
	font-weight: 500;
	@media (min-width: 992px) {
		margin-bottom: 40px;
		font-size: 40px;
	}
}

h4 {
	margin-bottom: 20px;
	font-size: 24px;
	font-weight: 500;
	@media (min-width: 768px) {
		margin-bottom: 30px;
		font-size: 30px;
	}
}

h5 {
	margin-bottom: 20px;
	font-size: 18px;
	font-weight: 700;
	@media (min-width: 768px) {
		font-size: 20px;
	}
}

h6 {
	margin-bottom: 20px;
	font-size: 1rem;
	font-weight: 700;
}

p {
	strong {
		font-size: 125%;
	}
}

em {
	font-style: normal;
	color: #34aadc;
}

small {
	font-size: 10px;
	@media (min-width: 768px) {
		font-size: 11px;
	}
}

.display-1, .display-2, .display-3, .display-4 {
	font-weight: 500;
}

.display-1 {
	font-size: 48px;
	@media (min-width: 768px) {
		font-size: 60px;
	}
}
.display-2 {
	font-size: 36px;
	@media (min-width: 768px) {
		font-size: 48px;
	}
}
.display-3 {
	font-size: 30px;
	@media (min-width: 768px) {
		font-size: 36px;
	}
}
.display-4 {
	font-size: 24px;
	@media (min-width: 768px) {
		font-size: 30px;
	}
}

.font-size-lg {
	font-size: 1rem;
	@media (min-width: 768px) {
		font-size: 24px;
	}
}
.font-size-sm {
	font-size: 12px;
	@media (min-width: 768px) {
		font-size: 14px;
	}
}

/* ===============================
	icons
 =============================== */

:before, :after {
	position: relative;
	display: inline-block;
	font-family: 'FontAwesome';
}



/* ===============================
	list
 =============================== */

ul, ol {
	margin: 0;
	padding-left: 10px;
}



/* ===============================
	button
 =============================== */

.btn {
	padding: .75rem;
	line-height: 1;
	font-weight: 700;
	white-space: nowrap;
	border-radius: 30px;
	&.btn-lg {
		padding: 1rem;
		font-size: 16px;
		border-radius: 30px;
	}
	
	@media (min-width: 768px) {
		padding: 1rem 1.5rem;
		min-width: 200px;
		transition: .3s;
		&.btn-lg {
			padding: 1.5rem 2rem;
			font-size: 20px;
			border-radius: 40px;
		}
	}
	@media (min-width: 992px) {
		padding: 1rem 2rem;
		min-width: 230px;
	}
	@media (min-width: 1200px) {
		&.btn-lg {
			padding: 1.5rem 3rem;
			font-size: 24px;
		}
	}
}

.btn:hover, .btn:active {
	cursor: pointer;
}
.btn:active, .btn.active,
.btn:focus, .btn.focus, 
.btn:active:focus, .btn:active.focus, 
.btn.active:focus, .btn.active.focus,
button:focus, button.focus {
    outline: none !important;
    box-shadow: none !important;
}

.btn-primary {
	color: #fff;
	background-color: #97d422;
	border: 2px solid #97d422;
	@media (min-width: 768px) {
		&.btn-lg {
			width: 700px;
		}
	}
	@media (min-width: 992px) {
		&:hover, &:active {
			color: #34aadc;
			background-color: #fff;
			border-color: #fff;
		}
		&.btn-lg {
			width: 400px;
		}
	}
	@media (min-width: 1200px) {
		&.btn-lg {
			width: 460px;
		}
	}
}

.btn-outline-primary {
	margin-top: 0;
	color: #fff;
	border: 2px solid #fff;
	@media (min-width: 768px) {
		&.btn-lg {
			width: 330px;
		}
	}
	@media (min-width: 992px) {
		&:hover, &:active {
			color: #34aadc;
			background-color: #fff;
			border-color: #fff;
		}
		&.btn-lg {
			width: 240px;
		}
	}
	@media (min-width: 1200px) {
		&.btn-lg {
			width: 300px;
		}
	}
}


/* ===============================
	nav
 =============================== */
 
.site-nav {
	margin-bottom: 70px;
	width: 100%;
	
	@media (min-width: 768px) {
		margin-bottom: 100px;
	}
}

#pagetop {
	display: block;
	position: fixed;
	right: 10px;
	bottom: 10px;
	width: 60px;
	height: 60px;
	color: #fff;
	text-align: center;
	background-color: #34aadc;
	border-radius: 30px;
	transform: rotate(180deg);
	z-index: 10000;
	opacity: 0;
	transition: 1s;
	i {
		margin: 0;
		font-size: 24px;
		line-height: 60px;
	}
	@media (min-width: 768px) {
		right: 20px;
		bottom: 20px;
	}
}



/* ===============================
	form
 =============================== */
 
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}


/* form ----------------------------- */

#satori__creative_container {
	margin-top: 30px;
	@media (min-width: 768px) {
		margin-top: 40px;
	}
}
.satori__custom_form_1dd1e02071d944ce_css {
	color: #fff !important;
	background: transparent !important;
}
.satori__custom_form_1dd1e02071d944ce_css .satori__input_group label:first-child {
  color: #fff !important;
}
.satori__require {
	background: transparent !important;
	color: #ff0000 !important;
	font-size: 12px !important;
}
.satori__custom_form_1dd1e02071d944ce_css .satori__input_group input[type=email], 
.satori__custom_form_1dd1e02071d944ce_css .satori__input_group input[type=number], 
.satori__custom_form_1dd1e02071d944ce_css .satori__input_group input[type=password], 
.satori__custom_form_1dd1e02071d944ce_css .satori__input_group input[type=tel], 
.satori__custom_form_1dd1e02071d944ce_css .satori__input_group input[type=text], 
.satori__custom_form_1dd1e02071d944ce_css .satori__input_group input[type=url], 
.satori__custom_form_1dd1e02071d944ce_css .satori__input_group textarea {
	background-color: rgba(255,255,255,.9) !important;
  border: none !important;
  border-radius: 6px;
  color: #22222c !important;
}
.satori__custom_form_1dd1e02071d944ce_css input[type=email]:focus, 
.satori__custom_form_1dd1e02071d944ce_css input[type=number]:focus, 
.satori__custom_form_1dd1e02071d944ce_css input[type=password]:focus, 
.satori__custom_form_1dd1e02071d944ce_css input[type=tel]:focus, 
.satori__custom_form_1dd1e02071d944ce_css input[type=text]:focus, 
.satori__custom_form_1dd1e02071d944ce_css input[type=url]:focus, 
.satori__custom_form_1dd1e02071d944ce_css textarea:focus {
  background-color: #fff !important;
}
.satori__custom_form_1dd1e02071d944ce_css select {
  border: none !important;
  background-color: rgba(255,255,255,.9) !important;
  padding: 13px !important;
  width: 100% !important;
  height: 44px !important;
  color: #22222c !important;
  border-radius: 6px;
  &:focus, &:active {
	  outline: none;
  }
  @media (min-width: 768px) {
	  height: 3.4em !important;
  }
}

.satori__custom_form_1dd1e02071d944ce_css a, 
.satori__custom_form_1dd1e02071d944ce_css a:visited {
  color: #97d422;
  text-decoration: none;
}

.satori__custom_form_1dd1e02071d944ce_css .satori__message {
	font-size: 10px !important;
	@media (min-width: 768px) {
		font-size: 14px !important;
	}
}
.satori__custom_form_1dd1e02071d944ce_css input[type=checkbox]+label, .satori__custom_form_1dd1e02071d944ce_css input[type=radio]+label {
	color: #fff !important;
}
.satori__custom_form_1dd1e02071d944ce_css input[type=checkbox]:checked+label, .satori__custom_form_1dd1e02071d944ce_css input[type=radio]:checked+label {
  color: #34aadc !important;
}

.satori__custom_form_1dd1e02071d944ce_css .satori__btn {
  background-color: #97d422 !important;
  color: #fff !important;
  width: 300px !important;
  padding: 1rem 2rem !important;
  border-radius: 40px !important;
  @media (min-width: 768px) {
	  width: 600px !important;
  }
  @media (min-width: 992px) {
		&:hover, &:active {
			color: #34aadc !important;
			background-color: #fff !important;
			border-color: #fff !important;
			opacity: 1 !important;
			cursor: pointer;
		}
	}
}



/* ===============================
	components
 =============================== */
 
header {
	&.site-header {
		padding: 15px 0;
		color: #fff;
		height: 540px;
		background: url(../imgs/bg_main.jpg) center top no-repeat;
		background-size: cover;
		
		@media (min-width: 768px) {
			height: 640px;
		}
		
		.site-description {
			display: block;
			margin-bottom: 10px;
			font-size: 11px;
		}
		.btn {
			margin-top: -6px;
			i:before {
				margin: 0;
			}
			span {
				display: none;
			}
		}
		
		.site-copy {
			margin-left: -15px;
			margin-right: -15px;
			text-align: center;
			.copy-equation {
				display: flex;
				flex-wrap: wrap;
				margin-left: auto;
				margin-right: auto;
				width: 300px;
				p {
					display: flex;
					flex-basis: 120px;
					height: 120px;
					font-size: 12px;
					justify-content: center;
					align-items: center;
					border: 2px solid #fff;
					border-radius: 100px;
					&:last-child {
						margin-left: auto;
						margin-right: auto;
						flex-basis: 100%;
						height: auto;
						font-size: 1rem;
						border: none;
					}
				}
				span {
					display: flex;
					flex-basis: 60px;
					height: 120px;
					line-height: 1;
					justify-content: center;
					align-items: center;
					&.equal {
						flex-basis: 100%;
						height: 40px;
						align-items: stretch;
					}
					.icon-equal {
						display: none;
					}
				}
				
				@media (min-width: 768px) {
					margin-left: auto;
					margin-right: auto;
					width: 722px;
					justify-content: center;
					p {
						flex-basis: 150px;
						height: 150px;
						font-size: 1rem;
						&:last-child {
							margin: 0;
							flex-basis: 150px;
							height: 150px;
							border: 2px solid #fff;
						}
					}
					span {
						flex-basis: 130px;
						height: 150px;
						font-size: 48px;
						&.equal {
							flex-basis: 130px;
							height: 150px;
						}
						i {
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
							margin: auto;
							height: 48px;
							&.icon-arrow {
								display: none;
							}
							&.icon-equal {
								display: block;
								height: 20px;
								font-size: 20px;
								&:before {
									margin: 0 0 0 -30px;
								}
							}
						}
					}
				}
			}
		}
		
		@media (min-width: 768px) {
			.btn {
				margin-top: -3px;
				i {
					display: none;
				}
				span {
					display: inline-block;
				}
			}
		}
	}
}

.bar-dot {
	padding: 20px 0 25px;
	width: 100%;
	color: #fff;
	line-height: 1.3;
	text-align: center;
	background: transparent url(../imgs/bg_dot.png) left top repeat;
	p {
		font-size: 20px;
	}
	.container {
		margin: 10px auto 0;
		letter-spacing: -.40em;
		> div {
			letter-spacing: normal;
		}
		.col-phone {
			font-size: 14px;
			strong {
				display: block;
				margin: 10px 0;
				font-size: 36px;
				font-weight: 700;
			}
		}
	}
	@media (max-width: 767px) {
		.container {
			.row {
				margin-left: -5px;
				margin-right: -5px;
				.btn {
					margin: 5px;
					font-weight: 500;
					&.btn-outline-primary {
						width: calc(50% - 10px);
					}
					&.btn-primary {
						width: 100%;
					}
				}
			}
		}
	}
	@media (min-width: 768px) {
		p {
			font-size: 30px;
			font-weight: 300;
		}
		.container {
			margin: 30px auto 10px;
			.row {
				margin-left: -5px;
				margin-right: -5px;
				.btn {
					margin: 10px;
					font-weight: 500;
				}
			}
		}
	}
	&#bar {
		display: none;
	}
}

.row > div {
	padding: 20px;
	@media (min-width: 768px) {
		padding: 30px;
	}
	@media (min-width: 992px) {
		padding: 20px;
	}
	@media (min-width: 1200px) {
		padding: 30px;
	}
}
 
section, .section {
	padding: 60px 0;
	text-align: center;
	z-index: 10;
	
	.container {
		transition: 1s;
	}
	
	&.section-intro {
		.col-intro-body {
			@extend .bg-gray;
			border: 5px solid #fff;
			img {
				margin: 15px auto 0;
				width: 110px;
			}
		}
		@media (min-width: 768px) {
			.col-intro-body {
				img {
					margin: 30px auto 0;
				}
			}
		}
		@media (min-width: 1200px) {
			.col-intro-body {
				border: 10px solid #fff;
			}
		}
	}
	
	&.section-about {
		background: transparent url(../imgs/bg_dark.jpg) center center no-repeat;
		background-size: cover;
		.content-induction {
			height: 150px;
			background: transparent url(../imgs/bg_induction.png) center center no-repeat;
			background-size: 100% 150px;
		}
		.content-about {
			margin-top: 60px;
			color: #fff;
			.col-about-body {
				padding: 5px 10px;
				text-align: left;
				.col-about-inner {
					display: block;
					padding: 20px;
					height: 100%;
					text-align: center;
					border: 2px solid #fff;
					.col-about-img {
						margin: 15px auto;
						width: 120px;
					}
				}
			}
			@media (min-width: 768px) {
				margin-top: 80px;
				.col-about-body {
					padding: 10px;
					.col-about-inner {
						padding: 30px;
					}
				}
			}
			@media (min-width: 992px) {
				.col-about-body {
					.col-about-inner {
						display: flex;
						padding: 30px;
						align-items: center;
						text-align: left;
						.col-about-img {
							margin: 15px 20px 15px 0;
							width: 100px;
						}
					}
				}
			}
			@media (min-width: 1200px) {
				.col-about-body {
					.col-about-inner {
						padding: 30px 40px;
						.col-about-img {
							margin: 15px 30px 15px 0;
							width: 160px;
						}
					}
				}
			}
		}
	}
	
	&.section-flow {
		.row {
			padding: 0;
		}
		.col-flow-body {
			padding: 40px 30px;
			height: 280px;
			color: #fff;
			font-size: 14px;
			line-height: 1.5;
			border: 10px solid #fff;
			&.col-flow01 {
				background: transparent url(../imgs/bg_flow01.jpg) center center no-repeat;
				background-size: contain;
			}
			&.col-flow02 {
				background: transparent url(../imgs/bg_flow02.jpg) center center no-repeat;
				background-size: contain;
			}
			&.col-flow03 {
				background: transparent url(../imgs/bg_flow03.jpg) center center no-repeat;
				background-size: contain;
			}
			&.col-flow04 {
				background: transparent url(../imgs/bg_flow04.jpg) center center no-repeat;
				background-size: contain;
			}
		}
		@media (max-width: 767px) {
			.col-flow-body {
				&:after {
					display: block;
					position: absolute;
					left: 0;
					right: 0;
					bottom: -30px;
					z-index: 10;
					margin-left: auto;
					margin-right: auto;
					width: 70px;
					height: 40px;
					content: "";
					background: transparent url(../imgs/img_flow_arrow_sp.png) center center no-repeat;
					background-size: 100%;
				}
				&:last-child:after {
					display: none;
				}
			}
		}
		@media (min-width: 768px) {
			.row {
				padding: 0 75px;
			}
		}
		@media (min-width: 992px) {
			.row {
				padding: 0;
			}
			.col-flow-body {
				padding: 40px 20px;
				font-size: 12px;
				&:after {
					display: block;
					position: absolute;
					top: 0;
					bottom: 0;
					right: -30px;
					z-index: 10;
					margin-top: auto;
					margin-bottom: auto;
					width: 30px;
					height: 60px;
					content: "";
					background: transparent url(../imgs/img_flow_arrow.png) center center no-repeat;
					background-size: 100%;
				}
				&:last-child:after {
					display: none;
				}
			}
		}
		@media (min-width: 1200px) {
			.col-flow-body {
				padding: 40px 30px;
				&:after {
					width: 40px;
					height: 70px;
				}
			}
		}
	}
	
	&.section-reasons {
		padding-top: 0;
		.container {
			margin: 0 15px;
			padding: 0 0 30px;
			&:before, &:after {
			  position: absolute;
			  content: '';
			  width: 50px;
			  height: 50px;
			}
			&:before {
			  top: 0;
			  left: 0;
			  border-top: 5px solid #22222c;
			  border-left: 5px solid #22222c;
			}
			&:after {
			  bottom: 0;
			  right: 0;
			  border-bottom: 5px solid #22222c;
			  border-right: 5px solid #22222c;
			}
		}
		ul {
			margin-left: auto;
			margin-right: auto;
			padding: 0 20px;
			max-width: 920px;
			text-align: left;
			li {
				padding-left: 50px;
				background:  transparent url(../imgs/img_reasons_check.png) left 5px no-repeat;
				background-size: 40px 40px;
				+ li {
					margin-top: 30px;
				}
				h6 {
					font-weight: 500;
					em {
						color: inherit;
						&:before {
							position: absolute;
							bottom: 2px;
							left: 0;
							z-index: -1;
							display: block;
							width: 100%;
							height: 5px;
							background-color: #34aadc;
							content: "";
							opacity: .5;
						}
					}
				}
				p {
					font-size: 12px;
				}
			}
		}
		@media (max-width: 767px) {
			.container {
				width: calc(100% - 30px);
			}
		}
		@media (min-width: 768px) {
			.container {
				margin: 0 auto;
				padding: 0 0 40px;
				&:before, &:after {
				  position: absolute;
				  content: '';
				  width: 100px;
				  height: 100px;
				}
			}
			ul {
				padding: 0;
				width: 600px;
				li {
					padding-left: 60px;
					background:  transparent url(../imgs/img_reasons_check.png) left top no-repeat;
					background-size: 45px 45px;
					+ li {
						margin-top: 40px;
					}
					h6 {
						font-size: 21px;
						em {
							&:before {
								height: 10px;
							}
						}
					}
					p {
						font-size: 14px;
					}
				}
			}
		}
		@media (min-width: 992px) {
			ul {
				width: 840px;
				li {
					h6 {
						font-size: 30px;
					}
				}
			}
		}
		@media (min-width: 1200px) {
			ul {
				width: 920px;
				li {
					padding-left: 80px;
					background:  transparent url(../imgs/img_reasons_check.png) left top no-repeat;
					background-size: 60px 60px;
				}
			}
		}
	}
	
	&.section-case {
		.row {
			padding: 0;
		}
		.col-case-body {
			padding: 0 20px 20px;
			.col-case-img {
			}
			.col-case-text {
				padding: 25px 20px;
				background-color: #fff;
				p {
					text-align: justify;
				}
			}
		}
		
		@media (min-width: 768px) {
			.col-case-body {
				.col-case-text {
					padding: 30px;
				}
			}
		}
		@media (min-width: 992px) {
			.col-case-body {
				padding: 0 20px 40px;
				.col-case-img {
					float: right;
					width: 460px;
				}
				.col-case-text {
					float: left;
					margin-top: 30px;
					width: 460px;
					min-height: 200px;
					text-align: left;
				}
			}	
		}
		@media (min-width: 1200px) {
			.col-case-body {
				.col-case-img {
					width: 550px;
				}
				.col-case-text {
					margin-top: 40px;
					padding: 40px;
					width: 550px;
					height: 240px;
				}
			}	
		}
	}
	
	&.section-companies {
		ul {
			margin-left: auto;
			margin-right: auto;
			li {
				margin: 10px;
			}
		}
	}
	
	&.section-companies {
		dl {
			margin: 0;
			padding: 15px 30px;
			flex-basis: 100%;
			text-align: left;
			dt {
				padding-left: 20px;
				margin-bottom: 1rem;
				&:before {
					position: absolute;
					top: 0;
					left: 0;
					color: #34aadc;
					content: "Q";
				}
			}
			dd {
				padding-left: 20px;
				font-size: 14px;
				text-align: justify;
				&:before {
					position: absolute;
					top: 0;
					left: 0;
					color: #97d422;
					content: "A";
				}
			}	
		}
		
		@media (min-width: 768px) {
			dl {
				padding: 20px 40px;
				flex-basis: 50%;
				max-width: 50%;
				dt, dd {
					padding-left: 30px;
				}
			}
		}
	}
	
	&.section-update {
		background: #eeeeee url(../imgs/logo_bg.png) center center no-repeat;
		background-size: 300px 77.25px;
		@media (min-width: 768px) {
			background-size: 400px 103px;
		}
		@media (min-width: 992px) {
			background-size: 800px 206px;
		}
	}
	
	&.section-form {
		.container {
			letter-spacing: normal;
		}
	}
	
	&.section-news {
		dl {
			margin: 0 auto;
			width: calc(100% - 20px);
			text-align: left;
			background-color: #eeeeee;
			+ dl {
				border-top: 2px solid #fff;
			}
			&:nth-child(even) {
				background-color: #f6f6f7;
			}
			dt, dd {
				padding: 1rem;
				font-weight: 400;
			}
			dt {
				padding-bottom: 0;
			}
			dd {
				margin-top: -1rem;
				margin-bottom: 0;
			}
			
			@media (min-width: 768px) {
				dl {
					margin: 0;
					width: 100%;
				}
				dt {
					position: absolute;
				}
				dd {
					margin-top: 0;
					padding-left: 10em;
				}
			}
		}
	}
	
	&.section-profile {
		padding-bottom: 30px;
		.row {
			border-top: 5px solid #22222c;
			dl {
				margin-bottom: 0;
				padding: 20px 10px;
				font-size: 12px;
				text-align: justify;
				dt {
					font-weight: 400;
				}
				+ dl {
					margin-top: -40px;
				}
			}
		}
		@media (max-width: 767px) {
			.row {
				margin: 0;
			}
		}
		@media (min-width: 768px) {
			.row {
				dl {
					padding: 40px 20px;
					font-size: 14px;
					dt {
						position: absolute;
					}
					dd {
						padding: 0 0 20px 6em;
					}
					+ dl {
						margin-top: -80px;
					}
				}
			}
		}
		@media (min-width: 992px) {
			.row {
				dl {
					padding: 40px 20px;
					flex-basis: 50%;
					+ dl {
						margin-top: 0;
					}
				}
			}
		}
	}

	@media (min-width: 768px) {
		padding: 80px 0;
	}
}

 
footer {
	&.site-footer {
		text-align: center;
		.container {
			padding: 60px 0;
			font-size: 12px;
			.site-logo-footer {
				margin: 10px auto 0;
				width: 155.5px;
				height: 40px;
			}
		}
		.copyright {
			padding: 1rem 0;
			line-height: 1.2;
		}
	}
	@media (min-width: 768px) {
		&.site-footer {
			.container {
				font-size: 14px;
				padding: 80px 0;
				.site-logo-footer {
					width: 311px;
					height: 80px;
				}
			}
		}
	}
}
